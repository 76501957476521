<template>
  <BaseCard :card-name="`${patientData.firstName} ${patientData.lastName}`">
    <div class="patient-card">
        <div class="descriptor-container" :style="descriptorStyle">
          <span class="descriptor-label">{{ tr('MRN') }}</span> <span class="datum" :style="datumStyle">{{ patientData.mrn }}</span>
        </div>
        <div class="age-sex">
          <div class="descriptor-container" :style="descriptorStyle">
            <span class="descriptor-label">{{ tr('Age') }}</span> <span class="datum" :style="datumStyle">{{ patientData.age }}</span>
          </div>
          <div class="descriptor-container" :style="descriptorStyle">
            <span class="descriptor-label">{{ tr('Sex') }}</span> <span class="datum" :style="datumStyle">{{ patientData.sex }}</span>
          </div>
        </div>
        <div class="descriptor-container" :style="descriptorStyle">
          <span class="descriptor-label">{{ tr('Primary Diagnosis') }}</span> <span class="datum" :style="datumStyle">{{ patientData.diagnosis }}</span>
        </div>
        <div class="descriptor-container" :style="descriptorStyle">
          <span class="descriptor-label">{{ tr('Staging') }}</span> <span class="datum" :style="datumStyle">{{ patientData.staging }}</span>
        </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { useStore } from 'vuex'
import { themeColors } from '@/common/shared.js'
import { computed, ref } from 'vue'
import { search } from '../../../components/ui/FindPatientModal/search.js'
import { diagnose, getId } from './patientEhr.js'
import BaseCard from '../BaseCard/BaseCard.vue'

const tr = (x) => x // inject('tr')
const store = useStore()

const query = 'janet' //TODO: this a placeholder for the selected patient

const calculateAge = (dob) => {
  const diffMs = Date.now() - dob.getTime()
  const ageDt = new Date(diffMs)

  return Math.abs(ageDt.getUTCFullYear() - 1970)
}

//TODO: the logic for this will likely change such that we already have the
//patient ID and don't necessarily need to do the search here
const loadPatientData = async () => {
  let patientData = {}

  const searchResults = await search(query)
  if (!searchResults?.length) { return }

  const firstResult = searchResults[0]
  patientData.firstName = firstResult.given || ''
  patientData.lastName = firstResult.family || ''
  patientData.sex = firstResult.gender || ''
  patientData.mrn = (firstResult.id?.length > 0 ? firstResult.id.split(',') : [''])[0]
  const birthdate = firstResult.birthdate || ''
  patientData.age = calculateAge(new Date(birthdate))

  const patientId = await getId(patientData.mrn)
  const diagnosis = await diagnose(patientId)

  //TODO: note that this should ultmately not be a list to choose from
  //but a 'canonical' chosen diagnosis for the tumor board
  //the endpoint in patientEhr.js will likely be changed
  patientData.diagnosis = (diagnosis.sample_description || { 0: '' })[0]

  //TODO: this likely should come from some endpoint
  patientData.staging = '3'
  return patientData
}

const descriptorStyle = computed(() => ({
  color: themeColors[store.getters.currentThemeName].generalDescriptorTextColorLightMode,
}))

const datumStyle = computed(() => ({
  color: themeColors[store.getters.currentThemeName].generalDatumTextColorLightMode,
}))

const patientData = ref(await loadPatientData())
</script>

<style lang="scss" scoped>
.patient-card {
  height: 30vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: left;
  font-size: calc(10px + 0.3vw);
  overflow: auto;
}

.descriptor-container{
  margin: 15px;
  display: inline-block;
  margin-right: 0.9vmax;
  align-content: left;
}

.datum{
  font-weight: 500;
}

.age-sex {
  display: flex;
}

.descriptor-label {
  display: block;
  align-content: left;
  text-transform: uppercase;
}
</style>