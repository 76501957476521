<template>
  <div class="wrapper">
    <BaseCard :card-name="tr('Patient Card')">
      <BaseSpinner class="base-spinner">
      </BaseSpinner>
    </BaseCard>
    <div class="info"></div>
  </div>
</template>

<script setup>
import BaseSpinner from '@/components/ui/icons/BaseSpinner.vue'
import BaseCard from '../BaseCard/BaseCard.vue'
const tr = (x) => x // inject('tr')
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: left;
  overflow: auto;
}

.base-spinner {
  margin-top: 2vmax;
  margin-left: 2vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}
</style>