<template>
  <BaseDrawer :is-open="isDrawerOpen" :speed="200" @close="closeDrawer">
    <div>
      <h2>Drawer Slot here</h2>
    </div>
  </BaseDrawer>
  <button class="drawer-button" :style="drawerButtonStyle" @click="toggleDrawer"><span>
      <BinIcon></BinIcon>
    </span></button>
  <div class="grid-container">
    <div class="grid-row">
      <div class="grid-item">
        <PatientSuspense></PatientSuspense>
      </div>
      <div class="grid-item">
        <ClinicalTimelineCard></ClinicalTimelineCard>
      </div>
    </div>
    <div class="grid-row">
      <div class="grid-item">
        <FilingCabinetCard></FilingCabinetCard>
      </div>
      <div class="grid-item">
        <PathologyCard></PathologyCard>
      </div>
      <div class="grid-item">
        <GenomicsCard></GenomicsCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { themeColors } from '@/common/shared.js'
import BaseDrawer from '@/ux3/components/BaseDrawer/BaseDrawer.vue'
import PatientSuspense from '@/ux3/cards/PatientCard/PatientSuspense.vue'
import ClinicalTimelineCard from '@/ux3/cards/ClinicalTimelineCard/ClinicalTimelineCard.vue'
import FilingCabinetCard from '@/ux3/cards/FilingCabinetCard/FilingCabinetCard.vue'
import PathologyCard from '@/ux3/cards/PathologyCard/PathologyCard.vue'
import GenomicsCard from '@/ux3/cards/GenomicsCard/GenomicsCard.vue'
import BinIcon from '@/ux3/icons/BinIcon.vue'

const store = useStore()

const drawerButtonStyle = computed(() => ({
  backgroundColor: themeColors[store.getters.currentThemeName].toolBackgroundColor,
  color: themeColors[store.getters.currentThemeName].toolHeaderTextColor,
}))

const isDrawerOpen = ref(false)
const toggleDrawer = () => {
  isDrawerOpen.value = !isDrawerOpen.value
}

const closeDrawer = () => {
  isDrawerOpen.value = false
}

onMounted(() => {
  const body = document.getElementsByTagName('body')[0]
  body.style.overflowY = 'hidden'
  body.style.fontFamily = 'Roboto'
  const app = document.getElementById('app')
  app.style.position = 'static'  // fix body margin problem
})
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 2rem;
  // height: 100vh;
  padding: 4rem 2rem 3rem 8rem;
}

.grid-row {
  display: grid;
  gap: 2rem;

  &:first-child {
    grid-template-columns: 15vw 4fr;
  }

  &:last-child {
    grid-template-columns: 15vw 2fr 2fr;
  }
}

.drawer-button {
  border-radius: 0 16px 16px 0;
  border-style: none;
  box-shadow: 0 0 3px #b9b9b9;
  width: 3rem;
  cursor: pointer;
  font: inherit;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &>span {
    display: block;
    margin: 0 auto;
    padding-top: 3rem;

    &>svg {
      width: 1.2rem;
    }
  }
}
</style>